import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import List from "list.js";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addApple,
  deleteApple,
  exportApple,
  importApple,
  soldApple,
} from "../../store/appleid/actions";
import AddNew from "./Global/AddNew";
import TableItem from "./Global/TableItem";
import AppleExport from "./Global/AppleExport";
import TableExport from "./Global/TableExport";

const Exports = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  const state = useSelector((state) => state);

  document.title = "Apple ID  | Grpiraq ID";

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.IDS.exports.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(state.IDS.exports.length / itemsPerPage);

  console.log(currentPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const setPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const maxVisiblePages = Math.min(totalPages, maxPageNumbers);

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Grpiraq" breadcrumbItem="Apple ID" />

          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                </CardHeader> */}

                <CardBody>
                  <div id="customerList">
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" style={{ width: "50px" }}>
                              {/* <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th> */}
                            <th className="sort" data-sort="customer_name">
                              ID
                            </th>
                            <th className="sort" data-sort="email">
                              File Name
                            </th>
                            <th className="sort" data-sort="phone">
                              Creation Date
                            </th>

                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {currentItems.map((exports) => (
                            <TableExport exports={exports} />
                          ))}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">No Exports</p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        {/* <ul className="pagination listjs-pagination mb-0"> */}
                        <div className="d-flex justify-content-end">
                          <div
                            className="btn-group me-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              className="page-item pagination-prev  btn btn-primary"
                              onClick={prevPage}
                              type="button"
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>

                            {getPageNumbers().map((pageNumber) => (
                              <button
                                type="button"
                                className="btn btn-primary"
                                key={pageNumber}
                                disabled={pageNumber === currentPage}
                                onClick={() => setPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            ))}

                            <button
                              disabled={currentPage === totalPages}
                              className="page-item pagination-prev  btn btn-primary"
                              type="button"
                              onClick={nextPage}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Exports;
