import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { useDispatch, useSelector } from "react-redux";
import { deleteAppleDefective, soldAppleQ } from "../../store/appleq/actions";
import ShowNew from "./Global/ShowNew";

import TableItemD from "./Global/TableItemD";

const AppleqDefectives = () => {
  //   const [modal_list, setmodal_list] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [modal_export, setmodal_export] = useState(false);
  const [modal_export_qr, setmodal_export_qr] = useState(false);
  //   const [files, setFiles] = React.useState(null);
  //   const [base64Image, setBase64Image] = useState("");
  const [editItem, setEditItem] = React.useState({});
  const [filteredData, setFilteredData] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    // If the checkbox is checked, add the value to the array
    if (checked) {
      setCheckedItems((prevState) => [...prevState, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setCheckedItems((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
  };

  const state = useSelector((state) => state);

  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  document.title = "Defective IDs  | grpiraq.net ID";

  function tog_center() {
    setmodal_center(!modal_center);
    setEditItem({});
  }

  function tog_export() {
    setmodal_export(!modal_export);
  }

  function tog_export_qr() {
    setmodal_export_qr(!modal_export_qr);
  }

  const handleEdit = (item) => {
    setEditItem(item);
    setmodal_center(!modal_center);
  };

  const handleDelete = (itemId) => {
    dispatch(deleteAppleDefective({ id: itemId }));
  };

  const handleSold = (itemId) => {
    dispatch(soldAppleQ({ id: itemId }));
  };

  const handleSearch = (text) => {
    const newQuery = text.target.value;

    const filtered = state.IDQ.defective.filter((id) => id.id == newQuery);
    setFilteredData(filtered);
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.IDQ.defective.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(state.IDQ.defective.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const setPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10;
    const maxVisiblePages = Math.min(totalPages, maxPageNumbers);

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="grpiraq.net" breadcrumbItem="Defective IDs" />

          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                </CardHeader> */}

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto"></Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => handleSearch(e)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" style={{ width: "50px" }}>
                              {/* <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th> */}
                            <th className="sort" data-sort="customer_name">
                              ID
                            </th>
                            <th className="sort" data-sort="email">
                              Email
                            </th>
                            <th className="sort" data-sort="phone">
                              Creation Date
                            </th>

                            <th className="sort" data-sort="status">
                              Availability
                            </th>
                            <th className="sort" data-sort="action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {filteredData.length === 0
                            ? currentItems.map((apple) => (
                                <TableItemD
                                  apple={apple}
                                  handleEdit={handleEdit}
                                  handleSold={handleSold}
                                  handleDelete={handleDelete}
                                  handleCheckboxChange={handleCheckboxChange}
                                  checkedItems={checkedItems}
                                />
                              ))
                            : filteredData.map((apple) => (
                                <TableItemD
                                  apple={apple}
                                  handleEdit={handleEdit}
                                  handleSold={handleSold}
                                  handleDelete={handleDelete}
                                  handleCheckboxChange={handleCheckboxChange}
                                  checkedItems={checkedItems}
                                />
                              ))}
                        </tbody>
                      </table>
                      <div className="noresult" style={{ display: "none" }}>
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ Orders We did not find
                            any orders for you search.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="pagination-wrap hstack gap-2">
                        {/* <ul className="pagination listjs-pagination mb-0"> */}
                        <div className="d-flex justify-content-end">
                          <div
                            className="btn-group me-2"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              className="page-item pagination-prev  btn btn-primary"
                              onClick={prevPage}
                              type="button"
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            {/* <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setPage(1)}
                              disabled={currentPage === 1}
                            >
                              1
                            </button> */}
                            {getPageNumbers().map((pageNumber) => (
                              <button
                                type="button"
                                className="btn btn-primary"
                                key={pageNumber}
                                disabled={pageNumber === currentPage}
                                onClick={() => setPage(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            ))}
                            {/* <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setPage(totalPages)}
                              disabled={currentPage === totalPages}
                            >
                              {totalPages}
                            </button> */}
                            <button
                              disabled={currentPage === totalPages}
                              className="page-item pagination-prev  btn btn-primary"
                              type="button"
                              onClick={nextPage}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                        {/* </ul> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {modal_center ? (
            <ShowNew
              modal_center={modal_center}
              setmodal_center={setmodal_center}
              tog_center={tog_center}
              data={editItem}
            />
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AppleqDefectives;
