import React from "react";

import { toast } from "react-toastify";

export default function TableItemD({ apple, handleEdit, handleDelete }) {
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const copied = () => {
    toast.success("Text Copied", {
      position: "bottom-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <tr key={apple.id}>
      <td className="id" style={{ display: "none" }}>
        {/* <Link to="#" className="fw-medium link-primary"> */}
        {apple.id}
        {/* </Link> */}
      </td>
      <td className="customer_name">{apple.id}</td>
      <td className="email">{apple.email}</td>

      <td className="date">{timeSince(new Date(apple.created_at))}</td>
      <td className="status">
        <span
          className={`badge  text-uppercase ${
            apple.state == true ? "badge-soft-warning" : "badge-soft-success"
          }`}
          style={{ fontSize: 14 }}
        >
          {apple.state == true ? "Sold" : "Available"}
        </span>
      </td>
      <td>
        <div className="d-flex gap-2">
          <div className="edit">
            <button
              className="btn  btn-md btn-success edit-item-btn"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              onClick={() => handleEdit(apple)}
            >
              <i className="ri-edit-line align-bottom "></i>
            </button>
          </div>

          <div className="remove">
            <button
              className="btn  btn-danger btn-md remove-item-btn"
              onClick={() => handleDelete(apple.id)}
            >
              <i class="ri-delete-bin-6-line  align-bottom"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}
