import Exports from "../../Pages/Mega/Exports";
import {
  ADD_EVENT_FAIL,
  ADD_EVENT_SUCCESS,
  GET_EVENTS_FAIL,
} from "../calendar/actionTypes";
import {
  ADD_GOOGLE_SUCCESS,
  DELETE_GOOGLE_SUCCESS,
  GET_GOOGLE_FAIL,
  GET_GOOGLE_SUCCESS,
  UPDATE_GOOGLE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  google: [],
  defective: [],
  exports: [],
  error: {},
};

const GOOGLE = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GOOGLE_SUCCESS:
      return {
        ...state,
        google: action.payload.google,
        defective: action.payload.defective,
      };

    case GET_GOOGLE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_GOOGLE_SUCCESS:
      return {
        ...state,
        google: [...state.google, action.payload],
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_GOOGLE_SUCCESS:
      return {
        ...state,
        google: state.google.map((id) =>
          id.id === action.payload.id ? { id, ...action.payload } : id
        ),
        isEventUpdated: true,
      };

    // case UPDATE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     isEventUpdated: false,
    //   };

    case DELETE_GOOGLE_SUCCESS:
      return {
        ...state,
        google: state.google.filter((id) => id.id !== action.payload),
      };

    // case DELETE_EVENT_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};

export default GOOGLE;
